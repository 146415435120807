import { defineComponent as o, openBlock as a, createBlock as n, resolveDynamicComponent as r, normalizeClass as s, withCtx as l, renderSlot as c } from "vue";
import { _ as i } from "./chunks/_plugin-vue_export-helper.CHgC5LLL.js";
import './css/Section.css';const f = /* @__PURE__ */ o({
  __name: "Section",
  props: {
    /**
     * The HTML tag to render.
     *
     * @default 'section'
     */
    is: {
      type: String,
      default: "section"
    },
    /**
     * The background color of the section.
     *
     * @default 'default'
     */
    background: {
      type: String,
      default: "default"
    },
    /**
     * adds padding to the bottom
     *
     * @default false
     */
    overlap: {
      type: Boolean,
      default: !1
    }
  },
  setup(e) {
    return (t, d) => (a(), n(r(e.is), {
      class: s(["section", [`section-${e.background}`, e.overlap ? "overlap" : ""]])
    }, {
      default: l(() => [
        c(t.$slots, "default", {}, void 0, !0)
      ]),
      _: 3
    }, 8, ["class"]));
  }
}), m = /* @__PURE__ */ i(f, [["__scopeId", "data-v-6e6f0f2e"]]);
export {
  m as default
};
